'use client';

import { LicenseInfo } from '@mui/x-license-pro';

import '../initialize';
import { env } from '@/env.mjs';

LicenseInfo.setLicenseKey(env.NEXT_PUBLIC_MUI_LICENSE_KEY);

export default function ClientInitializer() {
  return null;
}
