'use client';

import { ApolloProvider } from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { KBarProvider } from 'kbar';
import { useRouter } from 'next/navigation';
import { SnackbarProvider } from 'notistack';
import React, { ReactNode } from 'react';
import { SWRConfig } from 'swr';

import { Confirm } from '@/components/hooks/useConfirm';
import CommandBar, { kbarActions } from '@/components/layout/CommandBar';
import CustomThemeProvider from '@/components/layout/useCustomTheme';
import client from '@/graphql/apollo';

export default function ClientProviders({
  children,
}: {
  children?: ReactNode;
}) {
  const router = useRouter();

  return (
    <SWRConfig
      value={{
        keepPreviousData: true,
      }}
    >
      <CustomThemeProvider options={{ key: `mui` }}>
        <SnackbarProvider maxSnack={3}>
          <ApolloProvider client={client}>
            <Confirm />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <KBarProvider actions={kbarActions(router)}>
                <CommandBar />
                {children}
              </KBarProvider>
            </LocalizationProvider>
          </ApolloProvider>
        </SnackbarProvider>
      </CustomThemeProvider>
    </SWRConfig>
  );
}
