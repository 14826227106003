/* eslint-disable class-methods-use-this */
import Storage from '@/storage/Storage';

class MockedStorage implements Storage {
  readonly length: number = 0;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clear = (): void => {};

  getItem = (): string | null => null;

  key = (): string | null => null;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeItem = (): void => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setItem = (): void => {};
}

export default MockedStorage;
