'use client';

import React, { ReactNode } from 'react';

import Nav from '@/components/layout/Nav';
import { isReactNativeWebView } from '@/utils/isReactNativeWebView';

export default function Layout({ children }: { children?: ReactNode }) {
  if (isReactNativeWebView) {
    return <>{children}</>;
  }

  return <Nav>{children}</Nav>;
}
