'use client';

import { LoadingButton } from '@mui/lab';
import { Button as MuiButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { ComponentProps, useCallback, useState } from 'react';
import useSWR from 'swr';

type Button = {
  label: string;
  color?: ComponentProps<typeof MuiButton>['color'];
  variant?: ComponentProps<typeof MuiButton>['variant'];
  onClick?: { (): Promise<unknown> } | { (): unknown };
};

type Options = {
  title: string;
  message?: string;
  buttons: Button[];
};

export function Confirm() {
  const { options, confirm } = useConfirm();
  const [loading, setLoading] = useState(false);

  const handleClose = useCallback(() => confirm(null), [confirm]);
  if (!options) {
    return null;
  }
  const { message, title, buttons } = options;

  return (
    <Dialog onClose={handleClose} open maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>

      {message && (
        <DialogContent>
          <DialogContentText
            sx={{
              whiteSpace: `pre-wrap`,
            }}
          >
            {message}
          </DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        {buttons.map((button) => (
          <LoadingButton
            key={`${button.label}`}
            color={button.color}
            variant={button.variant}
            onClick={async () => {
              setLoading(true);
              try {
                if (button.onClick) {
                  await button.onClick();
                }
              } catch (e) {
                setLoading(false);
                throw e;
              }
              setLoading(false);
              await handleClose();
            }}
            loading={loading}
          >
            {button.label}
          </LoadingButton>
        ))}
      </DialogActions>
    </Dialog>
  );
}

export default function useConfirm() {
  const { data: options, mutate } = useSWR<Options | null>([`/confirm`], null);
  const confirm = useCallback(
    (_options: Options | null) => mutate(_options),
    [mutate],
  );

  return {
    options,
    confirm,
  };
}
