import MockedStorage from '@/storage/MockedStorage';
import Storage from '@/storage/Storage';

class PathStorage {
  private storage;

  constructor(storage: Storage) {
    const localStorageForcedClearDate = storage.getItem(
      `localStorageForcedClearDate`,
    );
    if (
      localStorageForcedClearDate == null ||
      +new Date(localStorageForcedClearDate) < 1667208038508
    ) {
      if (storage.getItem(`/shows`) !== null) {
        storage.removeItem(`/shows`);
      }

      storage.setItem(`localStorageForcedClearDate`, `${+new Date()}`);
    }

    this.storage = storage;
  }

  getFullPathOrDefault(path: string): string {
    return this.storage.getItem(path) ?? path;
  }

  setFullPath(path: string, fullPath: string) {
    this.storage.setItem(
      path,
      fullPath
        .replace(/\?page=\d+/, `?`)
        .replace(/&page=\d+/, ``)
        .replace(/\?&+/, `?`)
        .replace(/&+/, `&`)
        .replace(/[?&]+$/, ``),
    );
  }
}

const pathStorage = new PathStorage(
  global.window ? global.window.localStorage : new MockedStorage(),
);

export default pathStorage;
