import {
  ActionId,
  ActionImpl,
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarResults,
  KBarSearch,
  useMatches,
} from 'kbar';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import React from 'react';

export const kbarActions = (router: AppRouterInstance) => [
  {
    id: `calendar`,
    name: `calendar`,
    shortcut: [`g`, `c`],
    keywords: `calendar`,
    perform: () => {
      router.push(`/calendar`);
    },
  },
  {
    id: `artists`,
    name: `artists`,
    shortcut: [`g`, `a`],
    keywords: `artists`,
    perform: () => {
      router.push(`/artists`);
    },
  },
  {
    id: `shows`,
    name: `shows`,
    shortcut: [`g`, `s`],
    keywords: `shows`,
    perform: () => {
      router.push(`/shows`);
    },
  },
  {
    id: `pending artists`,
    name: `pending artists`,
    shortcut: [`g`, `p`],
    keywords: `pending artists`,
    perform: () => {
      router.push(`/artists/pending`);
    },
  },
  {
    id: `settlement`,
    name: `settlement`,
    shortcut: [`g`, `e`],
    keywords: `settlement`,
    perform: () => {
      router.push(`/shows/settlement`);
    },
  },
  {
    id: `me`,
    name: `me`,
    shortcut: [`g`, `m`],
    keywords: `me`,
    perform: () => {
      router.push(`/me`);
    },
  },
  {
    id: `venues`,
    name: `venues`,
    shortcut: [`g`, `v`],
    keywords: `venues`,
    perform: () => {
      router.push(`/venues`);
    },
  },
];

const groupNameStyle = {
  padding: `8px 16px`,
  fontSize: `10px`,
  textTransform: `uppercase` as const,
  opacity: 0.5,
};

function RenderResults() {
  const { results, rootActionId } = useMatches();

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === `string` ? (
          <div style={groupNameStyle}>{item}</div>
        ) : (
          <ResultItem
            action={item}
            active={active}
            currentRootActionId={rootActionId as string}
          />
        )
      }
    />
  );
}

// eslint-disable-next-line react/display-name
const ResultItem = React.forwardRef(
  (
    {
      action,
      active,
      currentRootActionId,
    }: {
      action: ActionImpl;
      active: boolean;
      currentRootActionId: ActionId;
    },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const ancestors = React.useMemo(() => {
      if (!currentRootActionId) return action.ancestors;
      const index = action.ancestors.findIndex(
        (ancestor) => ancestor.id === currentRootActionId,
      );
      // +1 removes the currentRootAction; e.g.
      // if we are on the "Set theme" parent action,
      // the UI should not display "Set theme… > Dark"
      // but rather just "Dark"
      return action.ancestors.slice(index + 1);
    }, [action.ancestors, currentRootActionId]);

    return (
      <div
        ref={ref}
        style={{
          padding: `12px 16px`,
          background: active ? `#333` : `transparent`,
          borderLeft: `2px solid ${active ? `#333` : `transparent`}`,
          display: `flex`,
          alignItems: `center`,
          justifyContent: `space-between`,
          cursor: `pointer`,
        }}
      >
        <div
          style={{
            display: `flex`,
            gap: `8px`,
            alignItems: `center`,
            fontSize: 14,
          }}
        >
          {action.icon && action.icon}
          <div style={{ display: `flex`, flexDirection: `column` }}>
            <div>
              {ancestors.length > 0 &&
                ancestors.map((ancestor) => (
                  <React.Fragment key={ancestor.id}>
                    <span
                      style={{
                        opacity: 0.5,
                        marginRight: 8,
                      }}
                    >
                      {ancestor.name}
                    </span>
                    <span
                      style={{
                        marginRight: 8,
                      }}
                    >
                      &rsaquo;
                    </span>
                  </React.Fragment>
                ))}
              <span>{action.name}</span>
            </div>
            {action.subtitle && (
              <span style={{ fontSize: 12 }}>{action.subtitle}</span>
            )}
          </div>
        </div>
        {action.shortcut?.length ? (
          <div
            aria-hidden
            style={{ display: `grid`, gridAutoFlow: `column`, gap: `4px` }}
          >
            {action.shortcut.map((sc) => (
              <kbd
                key={sc}
                style={{
                  padding: `4px 6px`,
                  background: `rgba(0 0 0 / .1)`,
                  borderRadius: `4px`,
                  fontSize: 14,
                }}
              >
                {sc}
              </kbd>
            ))}
          </div>
        ) : null}
      </div>
    );
  },
);
const searchStyle = {
  padding: `12px 16px`,
  fontSize: `16px`,
  width: `100%`,
  boxSizing: `border-box` as React.CSSProperties['boxSizing'],
  outline: `none`,
  border: `none`,
  background: `#000`,
  color: `#fff`,
};

const animatorStyle = {
  maxWidth: `600px`,
  width: `100%`,
  background: `#000`,
  color: `#fff`,
  borderRadius: `8px`,
  overflow: `hidden`,
  boxShadow: `#555`,
};

function CommandBar() {
  return (
    <KBarPortal>
      <KBarPositioner>
        <KBarAnimator style={animatorStyle}>
          <KBarSearch style={searchStyle} />
          <RenderResults />
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  );
}

export default CommandBar;
